.secondPageCLG {
  height: 120vh;
  width: 100vw;
  background-image: url("../img/pared.png");
  background-size: 100%;
  position: relative;
  z-index: 5;
  background-repeat: no-repeat;
}
.elige {
  position: absolute;
  z-index: 3;
  font-family: "Fragment Mono", monospace;
  font-size: 4em;
  color: white;
  margin-top: 0.7em;
  margin: 0.5em;
}

.estudioBack {
  height: 120vh;
  width: 100vw;
  position: absolute;
  transition: 1s;
  z-index: 1;
}
.estudioBackOut {
  transition: 1s;
  opacity: 0;
}

.veleros {
  height: 25em;
  width: 50em;
  margin-top: 55em;
  margin-left: -2em;
  border: 5px solid rgb(139, 116, 73);
  transition: 1s;
  box-shadow: 0px 0px 7px 0px black;
  position: absolute;
  z-index: 20;
}

.cuadroMoveVeleros {
  transform: translateX(90em) translateY(-45em) rotate(0deg);
  transition: 1.5s;
}
.cuadrosBackOut {
  display: none;
  transition: 1s;
}

.freshM {
  transform: rotate(90deg);
  height: 38em;
  width: 60em;
  margin-top: 30em;
  margin-left: -35em;
  border: 3px solid rgba(245, 222, 179, 0.502);
  /* border-radius: 5px; */
  box-shadow: 0px 0px 10px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 10;
}
.cuadroMoveFresh {
  transform: translateX(116em) translateY(-25em) rotate(0deg);
  transition: 1.5s;
}
.fiesta {
  transform: rotate(-90deg);
  height: 40em;
  width: 70em;
  margin-top: 25em;
  margin-left: -17em;
  border: 3px solid rgb(139, 116, 73);
  box-shadow: 0px 0px 7px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 9;
}
.cuadroMoveFiesta {
  transform: translateX(93em) translateY(-20em) rotate(0deg);
  transition: 1.5s;
}
.power {
  border: 3px solid rgb(139, 116, 73);
  box-shadow: 0px 0px 7px 0px black;
  margin-top: 42em;
  margin-left: -15em;
  height: 38em;
  transition: 1s;
  position: absolute;
  z-index: 120;
}
.cuadroMovePower {
  transform: translateX(105em) translateY(-35em) rotate(0deg);
  transition: 1.5s;
}
.blues {
  height: 50em;
  width: 30em;
  margin-top: 40em;
  margin-left: 0em;
  border: 3px solid rgb(139, 116, 73);

  box-shadow: 0px 0px 7px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 15;
  transform: rotate(90deg);
}
.cuadroMoveBlues {
  transform: translateX(100em) translateY(-35em) rotate(0deg);
  transition: 1.5s;
}
.gugen {
  transform: rotate(180deg);
  margin-left: 120em;
  height: 30em;
  width: 30em;
  margin-top: 50em;
  border: 3px solid rgb(139, 116, 73);

  box-shadow: 0px 0px 7px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 25;
}

.cuadroMoveGugen {
  transform: translateX(-25em) translateY(-40em) rotate(0deg);
  transition: 1s;
}
.vista {
  height: 30em;
  width: 40em;

  margin-top: 50em;
  margin-left: 90em;
  border: 3px solid rgb(139, 116, 73);
  box-shadow: 0px 0px 7px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 12;
}
.cuadroMoveVista {
  transform: translateX(0em) translateY(-39em);
  transition: 1s;
}
.fragua {
  transform: rotate(90deg);
  height: 25em;
  width: 40em;
  margin-top: 47em;
  margin-left: 118em;
  border: 3px solid rgb(139, 116, 73);
  box-shadow: 0px 0px 7px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 20;
}
.cuadroMoveFragua {
  transform: translateX(-30em) translateY(-35em) rotate(0deg);
  transition: 1s;
}
.bombay {
  transform: rotate(90deg);
  height: 30em;
  width: 50em;
  margin-top: 33em;
  margin-left: 95em;
  border: 3px solid rgb(139, 116, 73);
  box-shadow: 0px 0px 7px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 9;
}
.cuadroMoveBombay {
  transform: translateX(-10em) translateY(-25em) rotate(0deg);
  transition: 1s;
}

.ropa {
  height: 40em;
  width: 30em;
  margin-top: 40em;
  margin-left: 115em;
  border: 3px solid rgb(139, 116, 73);

  box-shadow: 0px 0px 7px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 18;
}
.cuadroMoveRopa {
  transform: translateX(-20em) translateY(-32em) rotate(0deg);
  transition: 1s;
}
.blues2 {
  transform: rotate(90deg);
  height: 40em;
  width: 70em;
  margin-top: 24em;
  margin-left: 95em;
  border: 3px solid rgb(139, 116, 73);
  /* border-radius: 5px; */
  box-shadow: 0px 0px 7px 0px black;
  transition: 1s;
  position: absolute;
  z-index: 13;
}
.cuadroMoveBlues2 {
  transform: translateX(-20em) translateY(-18em) rotate(0deg);
  transition: 1s;
}
.ojearBut {
  height: 5em;
  width: 5em;
  font-size: 1em;
  border-radius: 50%;
  background-color: rgb(238, 183, 56);
  position: absolute;
  z-index: 20;
  text-align: center;
  justify-content: center;
  padding: 0.5em;
}

.misObrasTit {
  font-family: "Train One", cursive;
  font-family: "Zen Tokyo Zoo", cursive;
  font-size: 7em;
  padding: 0.2em 0.7em;
  font-weight: 400;
  color: rgb(238, 183, 56);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184);
  text-align: center;
  justify-content: center;
  border-radius: 20px;
  margin-top: 0.5em;
  margin-left: 5em;
  position: absolute;
  /* background-color: rgba(150, 127, 255, 0.135); */
}

.misObrasMenu {
  height: 6em;
  width: 7em;
  padding: 0.7em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(127, 255, 212, 0.498);
  font-family: "Fragment Mono", monospace;
  font-size: 5em;
  font-weight: 400;
  color: white;
  position: absolute;
  z-index: 2;
  margin-top: 2em;
  margin-left: 20em;
  border-radius: 20px;
}

@media (min-width: 0px) and (max-width: 1050px) {
  .secondPageCLG {
    display: none;
  }
}

@media (min-width: 1051px) and (max-width: 1450px) {
  .secondPageCLG {
    height: 100vh;
    width: 100vw;
    background-image: none;
    background-color: rgb(243, 237, 225);
    display: flex;
    justify-content: center;
  }
  .cuadrosContainer {
    margin-top: 80px;
    background-image: url("../img/pared5.png");
    background-size: 1000px;
    background-repeat: no-repeat;
    width: 1500px;
    margin-left: 100px;
  }
  .elige {
    font-size: 2.3em;
    color: white;
    margin-top: 1em;
    margin: 0.5em;
  }

  .estudioBack {
    height: 100vh;
    width: 100vw;
  }

  .veleros {
    height: 150px;
    width: 300px;
    margin-top: 475px;
    margin-left: 0px;
    border: 4px solid rgb(139, 116, 73);
    transition: 1s;
    box-shadow: 0px 0px 7px 0px black;
    position: absolute;
    z-index: 20;
  }

  .cuadroMoveVeleros {
    transform: translateX(300px) translateY(-360px) rotate(0deg);
    transition: 1.5s;
  }
  .cuadrosBackOut {
    display: none;
    transition: 1s;
  }

  .freshM {
    transform: rotate(90deg);
    height: 250px;
    width: 390px;
    margin-top: 210px;
    margin-left: -250px;
    border: 4px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 10px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 10;
  }

  .cuadroMoveFresh {
    transform: translateX(600px) translateY(-125px) rotate(0deg);
    transition: 1.5s;
  }
  .fiesta {
    height: 240px;
    width: 350px;
    margin-top: 330px;
    margin-left: -130px;
  }
  .cuadroMoveFiesta {
    transform: translateX(570px) translateY(-235px) rotate(0deg);
    transition: 1.5s;
  }
  .power {
    border: 3px solid rgb(139, 116, 73);
    box-shadow: 0px 0px 7px 0px black;
    margin-top: 400px;
    margin-left: -90px;
    height: 220px;
    width: 220px;
    transition: 1s;
    position: absolute;
    z-index: 120;
  }
  .cuadroMovePower {
    transform: translateX(500px) translateY(-290px) rotate(0deg);
    transition: 1.5s;
  }
  .blues {
    height: 350px;
    width: 200px;
    margin-top: 340px;
    margin-left: 0px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 15;
    transform: rotate(90deg);
  }
  .cuadroMoveBlues {
    transform: translateX(400px) translateY(-260px) rotate(0deg);
    transition: 1.5s;
  }
  .gugen {
    transform: rotate(180deg);
    margin-left: 750px;
    height: 250px;
    width: 250px;
    margin-top: 375px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 25;
  }

  .cuadroMoveGugen {
    transform: translateX(-330px) translateY(-300px) rotate(0deg);
    transition: 1s;
  }
  .vista {
    height: 150px;
    width: 220px;
    margin-top: 475px;
    margin-left: 600px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 12;
  }
  .cuadroMoveVista {
    transform: translateX(-250px) translateY(-330px);
    transition: 1s;
  }
  .fragua {
    transform: rotate(90deg);
    height: 150px;
    width: 220px;
    margin-top: 440px;
    margin-left: 300px;
    border: 3px solid rgb(139, 116, 73);

    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 20;
  }
  .cuadroMoveFragua {
    transform: translateX(0px) translateY(-290px) rotate(0deg);
    transition: 1s;
  }
  .bombay {
    transform: rotate(90deg);
    height: 200px;
    width: 300px;
    margin-top: 375px;
    margin-left: 500px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 9;
  }
  .cuadroMoveBombay {
    transform: translateX(-90px) translateY(-250px) rotate(0deg);
    transition: 1s;
  }

  .ropa {
    height: 200px;
    width: 140px;
    margin-top: 455px;
    margin-left: 500px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    transform: rotate(90deg);
    z-index: 18;
  }
  .cuadroMoveRopa {
    transform: translateX(-100px) translateY(-350px) rotate(0deg);
    transition: 1s;
  }
  .blues2 {
    transform: rotate(90deg);
    height: 270px;
    width: 400px;
    margin-top: 290px;
    margin-left: 720px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 13;
  }
  .cuadroMoveBlues2 {
    transform: translateX(-400px) translateY(-200px) rotate(0deg);
    transition: 1s;
  }
  .ojearBut {
    height: 5em;
    width: 5em;
    font-size: 1em;
    border-radius: 50%;
    background-color: rgb(238, 183, 56);
    position: absolute;
    z-index: 20;
    text-align: center;
    justify-content: center;
    padding: 0.5em;
  }

  .misObrasTit {
    font-family: "Train One", cursive;
    font-family: "Zen Tokyo Zoo", cursive;
    font-size: 7em;
    padding: 0.2em 0.7em;
    font-weight: 400;
    color: rgb(238, 183, 56);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184);
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    margin-top: 0.5em;
    margin-left: 5em;
    position: absolute;
    /* background-color: rgba(150, 127, 255, 0.135); */
  }

  .misObrasMenu {
    height: 6em;
    width: 7em;
    padding: 0.7em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(127, 255, 212, 0.498);
    font-family: "Fragment Mono", monospace;
    font-size: 5em;
    font-weight: 400;
    color: white;
    position: absolute;
    z-index: 2;
    margin-top: 2em;
    margin-left: 20em;
    border-radius: 20px;
  }
}
@media (min-width: 1451px) and (max-width: 1900px) {
  .secondPageCLG {
    height: 900px;
    width: 100vw;
    background-image: none;
    background-color: rgb(243, 237, 225);
    display: flex;
    justify-content: center;
  }
  .cuadrosContainer {
    margin-top: 0px;
    background-image: url("../img/pared5.png");
    background-size: 1450px;
    background-repeat: no-repeat;
    width: 1500px;
    margin-left: 100px;
  }
  .elige {
    font-size: 2.3em;
    color: white;
    margin-top: 1em;
    margin: 0.5em;
  }

  .estudioBack {
    height: 100%;
    width: 100vw;
  }

  .veleros {
    height: 150px;
    width: 300px;
    margin-top: 555px;
    margin-left: 0px;
    border: 4px solid rgb(139, 116, 73);
    transition: 1s;
    box-shadow: 0px 0px 7px 0px black;
    position: absolute;
    z-index: 20;
  }

  .cuadroMoveVeleros {
    transform: translateX(400px) translateY(-330px) rotate(0deg);
    transition: 1.5s;
  }
  .cuadrosBackOut {
    display: none;
    transition: 1s;
  }

  .freshM {
    transform: rotate(90deg);
    height: 290px;
    width: 415px;
    margin-top: 210px;
    margin-left: -250px;
    border: 4px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 10px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 10;
  }

  .cuadroMoveFresh {
    transform: translateX(700px) translateY(-60px) rotate(0deg);
    transition: 1.5s;
  }
  .fiesta {
    height: 280px;
    width: 400px;
    margin-top: 370px;
    margin-left: -130px;
  }
  .cuadroMoveFiesta {
    transform: translateX(670px) translateY(-180px) rotate(0deg);
    transition: 1.5s;
  }
  .power {
    border: 3px solid rgb(139, 116, 73);
    box-shadow: 0px 0px 7px 0px black;
    margin-top: 458px;
    margin-left: -110px;
    height: 250px;
    width: 250px;
    transition: 1s;
    position: absolute;
    z-index: 120;
  }
  .cuadroMovePower {
    transform: translateX(620px) translateY(-240px) rotate(0deg);
    transition: 1.5s;
  }
  .blues {
    height: 400px;
    width: 240px;
    margin-top: 381px;
    margin-left: 0px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 15;
    transform: rotate(90deg);
  }
  .cuadroMoveBlues {
    transform: translateX(500px) translateY(-180px) rotate(0deg);
    transition: 1.5s;
  }
  .gugen {
    transform: rotate(180deg);
    margin-left: 950px;
    height: 300px;
    width: 300px;
    margin-top: 405px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 25;
  }

  .cuadroMoveGugen {
    transform: translateX(-350px) translateY(-245px) rotate(0deg);
    transition: 1s;
  }
  .vista {
    height: 250px;
    width: 320px;
    margin-top: 458px;
    margin-left: 800px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 12;
  }
  .cuadroMoveVista {
    transform: translateX(-250px) translateY(-280px);
    transition: 1s;
  }
  .fragua {
    height: 250px;
    width: 320px;
    margin-top: 420px;
    margin-left: 1100px;
    border: 3px solid rgb(139, 116, 73);

    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 20;
  }
  .cuadroMoveFragua {
    transform: translateX(-500px) translateY(-235px) rotate(0deg);
    transition: 1s;
  }
  .bombay {
    transform: none;
    height: 280px;
    width: 400px;
    margin-top: 430px;
    margin-left: 500px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 9;
  }
  .cuadroMoveBombay {
    transform: translateX(0px) translateY(-260px) rotate(0deg);
    transition: 1s;
  }

  .ropa {
    height: 300px;
    width: 215px;
    margin-top: 450px;
    margin-left: 500px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    transform: rotate(90deg);
    z-index: 18;
  }
  .cuadroMoveRopa {
    transform: translateX(-50px) translateY(-250px) rotate(0deg);
    transition: 1s;
  }
  .blues2 {
    transform: rotate(90deg);
    height: 350px;
    width: 500px;
    margin-top: 190px;
    margin-left: 850px;
    border: 3px solid rgb(139, 116, 73);
    /* border-radius: 5px; */
    box-shadow: 0px 0px 7px 0px black;
    transition: 1s;
    position: absolute;
    z-index: 13;
  }
  .cuadroMoveBlues2 {
    transform: translateX(-350px) translateY(-50px) rotate(0deg);
    transition: 1s;
  }
  .ojearBut {
    height: 5em;
    width: 5em;
    font-size: 1em;
    border-radius: 50%;
    background-color: rgb(238, 183, 56);
    position: absolute;
    z-index: 20;
    text-align: center;
    justify-content: center;
    padding: 0.5em;
  }

  .misObrasTit {
    font-family: "Train One", cursive;
    font-family: "Zen Tokyo Zoo", cursive;
    font-size: 7em;
    padding: 0.2em 0.7em;
    font-weight: 400;
    color: rgb(238, 183, 56);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184);
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    margin-top: 0.5em;
    margin-left: 5em;
    position: absolute;
    /* background-color: rgba(150, 127, 255, 0.135); */
  }

  .misObrasMenu {
    height: 6em;
    width: 7em;
    padding: 0.7em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(127, 255, 212, 0.498);
    font-family: "Fragment Mono", monospace;
    font-size: 5em;
    font-weight: 400;
    color: white;
    position: absolute;
    z-index: 2;
    margin-top: 2em;
    margin-left: 20em;
    border-radius: 20px;
  }
}
