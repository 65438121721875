.footer {
  height: 6em;
  width: 100vw;
  background-color: rgba(59, 112, 124, 0.631);
  padding: 1em;
  /* border-top: 2px solid rgba(43, 105, 4, 0.459); */
}
.footer__text__p {
  color: white;
  font-size: 1em;
  text-align: center;
  font-family: "Fragment Mono", monospace;
}
