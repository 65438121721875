.mainContDishh {
  height: 100vh;
  width: 100vw;
  background-color: rgb(107, 144, 101);
}
.explicando {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  align-content: center;
  background-color: white;
  padding: 1em;
  border-radius: 10px;
  margin: 1em;
  font-family: "Fragment Mono", monospace;
  font-size: 0.5em;
  height: 15%;
  margin-top: 5em;
}
