:root {
  box-sizing: border-box;
  font-size: 0.785rem;
}

*,
::before,
::after {
  box-sizing: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  outline: none 0;
  list-style: none;
  text-decoration: none;
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
