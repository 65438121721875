.mainGlow {
  height: 100vh;
  width: 100%;
  background-image: url("../img/GlowSite/flores.png ");
  background-size: cover;
  background-repeat: repeat-x;
  color: white;
  font-size: 3em;
  font-family: "Poppins";
}

.mail {
  height: 1.6em;
  width: 1.6em;
  position: absolute;
  z-index: 3;
  margin-top: 17.7em;
  background-color: rgba(247, 247, 248, 0.281);
  margin-left: 1.5em;
  padding: 0.2em;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.485);
  transition: 1s;
}
.mail:hover {
  cursor: pointer;
  transform: scale(1.2);
  background-color: rgba(21, 238, 223, 0.695);
}
.bubbleA {
  font-family: "Zen Tokyo Zoo", cursive;
  font-family: "Amatic SC", cursive;
  font-family: "Barriecito", cursive;
  font-family: "Chokokutai", cursive;
  font-family: "Codystar", cursive;
  font-family: "Fragment Mono", monospace;
  /* font-family: "Martian Mono", monospace; */
  /* font-family: "Rubik Vinyl", cursive; */
  /* font-family: "Sono", sans-serif; */
  /* font-family: "Syncopate", sans-serif; */
  /* font-family: "Train One", cursive; */
  font-size: 0.8em;
  width: 25em;
  height: 5em;
  color: rgb(3, 73, 3);
  display: flex;
  /* background-color: rgba(0, 255, 255, 0.669); */
  padding: 0.5em;
  border-left: 3px solid rgba(247, 144, 10, 0.184);
  /* border-bottom: 3px solid rgba(247, 144, 10, 0.234); */
  margin-bottom: 3em;
  margin-top: 16em;
  margin-left: 3em;
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}
.moveBubbleA {
  margin-left: 14.5em;
  transition: 2s;
  margin-top: 14.7em;
}
.whiteLogoG {
  height: 1em;
  width: 1em;
}
.logoTit {
  background-color: rgba(245, 246, 244, 0.689);
  margin-left: 4em;
  font-family: "Zen Tokyo Zoo", cursive;
  font-family: "Martian Mono", monospace;
  font-family: "Rubik Vinyl", cursive;
  font-family: "Sono", sans-serif;
  font-family: "Syncopate", sans-serif;
  font-family: "Train One", cursive;
  font-family: "Amatic SC", cursive;
  font-family: "Barriecito", cursive;
  font-family: "Chokokutai", cursive;
  font-family: "Codystar", cursive;
  color: rgb(14, 105, 133);
  font-weight: 800;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  height: 1.5em;
  position: absolute;
  display: flex;
  border-radius: 20px;
  font-size: 2.5em;
  flex-direction: row;
  margin-top: 0.7em;
  padding: 0.5em;
  align-items: center;
  justify-content: space-between;
}
.bubbleB {
  font-family: "Zen Tokyo Zoo", cursive;
  font-family: "Amatic SC", cursive;
  font-family: "Barriecito", cursive;
  font-family: "Chokokutai", cursive;
  font-family: "Codystar", cursive;
  /* font-family: "Fragment Mono", monospace; */
  /* font-family: "Martian Mono", monospace; */
  /* font-family: "Rubik Vinyl", cursive; */
  /* font-family: "Sono", sans-serif; */
  /* font-family: "Syncopate", sans-serif; */
  /* font-family: "Train One", cursive; */
  font-size: 2.5em;
  background-color: rgba(255, 85, 0, 0.136);
  font-weight: 800;
  display: flex;
  color: rgb(245, 246, 248);
  border-left: 4px solid rgb(247, 144, 10);
  border-bottom: 3px solid rgba(247, 144, 10, 0.118);
  margin-bottom: 3em;
  margin-top: 1em;
  width: 2em;
  margin-left: 0.3em;
  position: absolute;
}
.bubbleC {
  font-family: "Fragment Mono", monospace;
  /* font-family: "Martian Mono", monospace; */
  /* font-family: "Rubik Vinyl", cursive; */
  /* font-family: "Sono", sans-serif; */
  /* font-family: "Syncopate", sans-serif; */
  /* font-family: "Train One", cursive; */
  font-size: 1.3em;
  background-color: rgba(255, 72, 0, 0.623);
  width: 8em;
  height: 8em;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);

  color: rgb(50, 171, 241);
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 1em;
  border-left: 3px solid rgba(10, 176, 247, 0.118);
  border-bottom: 3px solid rgba(247, 144, 10, 0.118);
  margin-bottom: 3em;
  margin-top: 5em;
  margin-left: 30em;
  position: absolute;
  border-radius: 15px;
}
.glowList {
  color: rgb(50, 171, 241);
}
.glowList:hover {
  color: white;
  transition: 0.5s;
}
.bubbleC:hover {
  box-shadow: none;
}
.formContainerGlow {
  height: 7.7em;
  width: 16em;
  padding: 0.5em;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 12em;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);

  background-color: rgba(46, 139, 86, 0.564);
  font-family: "Fragment Mono", monospace;
  border-radius: 20px;
  margin-left: 1em;
  .form {
    /* background-color: aquamarine; */
    height: 60%;
    font-size: 0.8em;

    .formSection {
      /* background-color: brown; */
      height: 30%;
      width: 100%;
      display: flex;
      .formTit {
        /* background-color: blue; */
        height: 100%;
        width: 40%;
      }
      .formInput {
        margin-left: auto;
        height: 80%;
        font-size: 1em;
        width: 60%;
        background-color: rgba(186, 225, 203, 0.284);
        color: white;
        border: 2px solid rgba(255, 255, 255, 0.439);
        border-radius: 10px;
      }
    }
  }

  .contactButton {
    background-color: rgba(0, 255, 251, 0.577);
    width: 70%;
    border-radius: 10px;
    height: 18%;
    font-family: "Fragment Mono", monospace;
    font-size: 0.6em;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.767);
  }
  .contactButton:hover {
    color: yellow;
    font-weight: 400;
  }
}
.moveMail {
  margin-top: 12em;
  margin-left: 3.7em;
  transition: 1s;
}
.formOut {
  display: none;
}
.awesome {
  animation: awesomeEffect 10s infinite;
  margin-left: 10em;
}
.awesomeMove {
  transition: 2s;
  border: 4px double rgba(255, 255, 255, 0.767);
  padding: 0.5em;
  border-radius: 50%;
}

@keyframes awesomeEffect {
  0% {
    transform: scale(1);
    color: red;
    border-color: transparent;
  }
  50% {
    transform: scale(1.2);
    color: white;
    border-color: transparent;
  }
  100% {
    transform: scale(1.3);
    color: aqua;
    border-color: white;
  }
}
@media (min-width: 0px) and (max-width: 279px) {
  .mainGlow {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 380px) {
  .mainGlow {
    height: 100vh;
    width: 100vw;
    background-size: 150%;
    margin-left: 0em;
    font-size: 1em;
    background-image: url("../img/GlowSite/vertical.png ");
  }

  .mail {
    display: none;
  }

  .bubbleA {
    font-size: 1.2em;
    width: 15em;
    height: 2em;
    padding: 0.5em;
    margin-bottom: 3em;
    margin-top: 16em;
    margin-left: -6em;
    /* background-color: blue; */
  }
  .moveBubbleA {
    margin-left: 2em;
    transition: 2s;
    margin-top: 16.5em;
  }
  .whiteLogoG {
    height: 1.2em;
    width: 1.2em;
  }
  .logoTit {
    background-color: rgba(245, 246, 244, 0.689);
    margin-left: 0.5em;
    font-weight: 800;
    height: 3em;
    width: 10em;
    font-size: 2em;
    margin-top: 0.7em;
    padding: 0.5em;
  }

  /* orange */
  .bubbleC {
    font-size: 1.3em;
    width: 8em;
    height: 7em;
    padding: 1em;
    border-left: 3px solid rgba(10, 176, 247, 0.118);
    border-bottom: 3px solid rgba(247, 144, 10, 0.118);
    margin-bottom: 3em;
    margin-top: 7em;
    margin-left: 5em;
  }

  /* get into glow */
  .bubbleB {
    font-family: "Codystar", cursive;
    font-size: 3.2em;
    background-color: rgba(255, 85, 0, 0.136);
    font-weight: 800;
    color: rgb(245, 246, 248);
    border-left: 4px solid rgb(247, 144, 10);
    border-bottom: 3px solid rgba(247, 144, 10, 0.118);
    margin-bottom: 3em;
    margin-top: 9.5em;
    width: 2em;
    margin-left: 0.5em;
  }

  .formContainerGlow {
    height: 8em;
    width: 16em;
    padding: 0.5em;
    margin-top: 43em;
    border-radius: 20px;
    margin-left: 5em;
    .form {
      height: 60%;
      font-size: 0.8em;
      .formSection {
        height: 30%;
        width: 100%;
        .formTit {
          height: 100%;
          width: 40%;
        }
        .formInput {
          margin-left: auto;
          height: 80%;
          font-size: 1em;
          width: 60%;
          border: 2px solid rgba(255, 255, 255, 0.439);
        }
      }
    }

    .contactButton {
      background-color: rgba(0, 255, 251, 0.577);
      width: 70%;
      border-radius: 10px;
      height: 18%;
      font-size: 0.6em;
      color: white;
      border: 2px solid rgba(255, 255, 255, 0.767);
    }
    .contactButton:hover {
      color: yellow;
      font-weight: 400;
    }
  }
  .moveMail {
    margin-top: 12em;
    margin-left: 3.7em;
    transition: 1s;
  }
  .formOut {
    display: block;
  }
  .awesome {
    animation: awesomeEffect 10s infinite;
    margin-left: 3em;
    margin-top: 2em;
    height: 8em;
    width: 8em;
  }
  .awesomeMove {
    transition: 2s;
    border: 4px double rgba(255, 255, 255, 0.767);
    padding: 0.5em;
    border-radius: 50%;
  }
}
@media (min-width: 381px) and (max-width: 700px) {
  .mainGlow {
    height: 140vh;
    width: 100vw;
    background-size: 170%;
    margin-left: 0em;
    font-size: 1em;
    background-image: url("../img/GlowSite/vertical.png ");
  }
  .logoTit {
    background-color: rgba(245, 246, 244, 0.689);
    margin-left: 0.5em;
    font-weight: 800;
    height: 3em;
    width: 10em;
    font-size: 2.9em;
    margin-top: 0.7em;
    padding: 0.5em;
  }

  .mail {
    display: none;
  }

  .bubbleA {
    font-size: 1.5em;
    width: 15em;
    height: 2em;
    padding: 0.5em;
    margin-bottom: 3em;
    margin-top: 14em;
    margin-left: -6em;
    /* background-color: blue; */
  }
  .moveBubbleA {
    margin-left: 5em;
    transition: 2s;
    margin-top: 16em;
  }
  .whiteLogoG {
    height: 1.4em;
    width: 1.4em;
  }

  /* orange */
  .bubbleC {
    font-size: 1.6em;
    width: 8em;
    height: 7em;
    padding: 1em;
    border-left: 3px solid rgba(10, 176, 247, 0.118);
    border-bottom: 3px solid rgba(247, 144, 10, 0.118);
    margin-bottom: 3em;
    margin-top: 8em;
    margin-left: 6em;
  }

  /* get into glow */
  .bubbleB {
    font-size: 4.5em;
    font-weight: 800;
    color: rgb(245, 246, 248);
    border-left: 4px solid rgb(247, 144, 10);
    border-bottom: 3px solid rgba(247, 144, 10, 0.118);
    margin-bottom: 3em;
    margin-top: 8.5em;
    width: 2em;
    margin-left: 0.5em;
  }

  .formContainerGlow {
    height: 10em;
    width: 22em;
    padding: 0.5em;
    margin-top: 60em;
    border-radius: 20px;
    margin-left: 7em;
    .form {
      height: 60%;
      font-size: 0.8em;
      .formSection {
        height: 30%;
        width: 100%;
        .formTit {
          height: 100%;
          width: 40%;
        }
        .formInput {
          margin-left: auto;
          height: 80%;
          font-size: 1em;
          width: 60%;
          border: 2px solid rgba(255, 255, 255, 0.439);
        }
      }
    }

    .contactButton {
      background-color: rgba(0, 255, 251, 0.577);
      width: 70%;
      border-radius: 10px;
      height: 18%;
      font-size: 0.6em;
      color: white;
      border: 2px solid rgba(255, 255, 255, 0.767);
    }
    .contactButton:hover {
      color: yellow;
      font-weight: 400;
    }
  }
  .moveMail {
    margin-top: 12em;
    margin-left: 3.7em;
    transition: 1s;
  }
  .formOut {
    display: block;
  }
  .awesome {
    animation: awesomeEffect 10s infinite;
    margin-left: 3em;
    margin-top: 2em;
    height: 8em;
    width: 8em;
  }
  .awesomeMove {
    transition: 2s;
    border: 4px double rgba(255, 255, 255, 0.767);
    padding: 0.5em;
    border-radius: 50%;
  }
}
@media (min-width: 701px) and (max-width: 1200px) {
  .mainGlow {
    height: 215vh;
    width: 100vw;
    background-size: 120%;
    margin-left: 0em;
    font-size: 1em;
    background-image: url("../img/GlowSite/vertical.png ");
  }
  .logoTit {
    background-color: rgba(245, 246, 244, 0.689);
    margin-left: 0.5em;
    font-weight: 800;
    height: 3em;
    width: 10em;
    font-size: 5em;
    margin-top: 0.7em;
    padding: 0.5em;
  }

  .mail {
    display: none;
  }

  .bubbleA {
    font-size: 2.3em;
    width: 15em;
    height: 2em;
    padding: 0.5em;
    margin-bottom: 3em;
    margin-top: 14em;
    margin-left: -6em;
    /* background-color: blue; */
  }
  .moveBubbleA {
    margin-left: 5em;
    transition: 2s;
    margin-top: 18em;
  }
  .whiteLogoG {
    height: 1.4em;
    width: 1.4em;
  }

  /* orange */
  .bubbleC {
    font-size: 2.5em;
    width: 8em;
    height: 7em;
    padding: 1em;
    border-left: 3px solid rgba(10, 176, 247, 0.118);
    border-bottom: 3px solid rgba(247, 144, 10, 0.118);
    margin-bottom: 3em;
    margin-top: 8em;
    margin-left: 7em;
  }

  /* get into glow */
  .bubbleB {
    font-size: 7em;
    font-weight: 800;
    color: rgb(245, 246, 248);
    border-left: 4px solid rgb(247, 144, 10);
    border-bottom: 3px solid rgba(247, 144, 10, 0.118);
    margin-bottom: 3em;
    margin-top: 8em;
    width: 2em;
    margin-left: 0.5em;
  }

  .formContainerGlow {
    height: 15em;
    width: 35em;
    padding: 1em;
    margin-top: 85em;
    border-radius: 20px;
    margin-left: 11em;
    .form {
      height: 60%;
      font-size: 1.8em;
      .formSection {
        height: 30%;
        width: 100%;
        .formTit {
          height: 100%;
          width: 40%;
        }
        .formInput {
          margin-left: auto;
          height: 80%;
          font-size: 1em;
          width: 60%;
          border: 2px solid rgba(255, 255, 255, 0.439);
        }
      }
    }

    .contactButton {
      background-color: rgba(0, 255, 251, 0.577);
      width: 70%;
      border-radius: 10px;
      height: 18%;
      font-size: 0.6em;
      color: white;
      border: 2px solid rgba(255, 255, 255, 0.767);
    }
    .contactButton:hover {
      color: yellow;
      font-weight: 400;
    }
  }
  .moveMail {
    margin-top: 12em;
    margin-left: 3.7em;
    transition: 1s;
  }
  .formOut {
    display: block;
  }
  .awesome {
    animation: awesomeEffect 10s infinite;
    margin-left: 3em;
    margin-top: 2em;
    height: 8em;
    width: 8em;
  }
  .awesomeMove {
    transition: 2s;
    border: 4px double rgba(255, 255, 255, 0.767);
    padding: 0.5em;
    border-radius: 50%;
  }
}
@media (min-width: 1201px) and (max-width: 1700px) {
  .logoTit {
    background-color: rgba(245, 246, 244, 0.689);
    margin-left: 0.5em;
    font-weight: 800;
    height: 1.5em;
    width: 15em;
    font-size: 2em;
    margin-top: 0.7em;
    padding: 0.5em;
  }
  .formContainerGlow {
    height: 7em;
    width: 11em;
    padding: 0.5em;
    margin-top: 13em;
    margin-left: 1em;
    .form {
      /* background-color: aquamarine; */
      height: 60%;
      font-size: 0.6em;

      .formSection {
        /* background-color: brown; */
        height: 30%;
        width: 100%;
        display: flex;
        .formTit {
          /* background-color: blue; */
          height: 100%;
          width: 40%;
        }
        .formInput {
          margin-left: auto;
          height: 80%;
          font-size: 1em;
          width: 60%;
          background-color: rgba(186, 225, 203, 0.284);
          color: white;
          border: 2px solid rgba(255, 255, 255, 0.439);
          border-radius: 10px;
        }
      }
    }
  }
  /* get into glow */
  .bubbleB {
    font-size: 1.9em;
    font-weight: 800;
    color: rgb(245, 246, 248);
    border-left: 4px solid rgb(247, 144, 10);
    border-bottom: 3px solid rgba(247, 144, 10, 0.118);
    margin-bottom: 3em;
    margin-top: 3em;
    width: 2em;
    margin-left: 0.5em;
  }
  /* orange */
  .bubbleC {
    font-size: 1em;
    width: 8em;
    height: 7em;
    padding: 1em;
    border-left: 3px solid rgba(10, 176, 247, 0.118);
    border-bottom: 3px solid rgba(247, 144, 10, 0.118);
    margin-bottom: 3em;
    margin-top: 11em;
    margin-left: 22em;
  }
  .formOut {
    display: block;
  }
  .mail {
    display: none;
  }
  .moveBubbleA {
    color: aquamarine;
    font-size: 0.7em;
    margin-left: 8em;
    transition: 2s;
    margin-top: 6em;
  }
  .awesome {
    margin-left: 3em;
    margin-top: 2em;
  }
}
@media (min-width: 1701px) and (max-width: 1900px) {
  .logoTit {
    height: 2.5em;
    width: 15em;
    font-size: 2.2em;
  }

  .bubbleC {
    margin-left: 26.5em;
    margin-top: 7em;
  }
}
